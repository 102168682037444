<template>
  <div class="">
    <v-card outlined class="transparent">
      <v-card-title class="text-h4 font-weight-bold mb-1">
        {{ company.name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row justify="center" align="center" style="width: 100%">
          <v-col cols="12">
            <div class="d-flex flex-column justify-center align-center">
              <div class="d-flex">
                <v-card
                  outlined
                  @click="$router.push({ name: 'dashboard-accounts' })"
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/budget.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('accounts') }}</h3>
                  </v-card-title>
                </v-card>
                <v-card
                  outlined
                  v-if="$service.hideFrom((v) => [v == 5 || v == 9])"
                  @click="$router.push({ name: 'hr-users' })"
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/hr.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('hr') }}</h3>
                  </v-card-title>
                </v-card>
                <v-card
                  outlined
                  @click="$router.push({ name: 'dashboard-warehouses' })"
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/warehouse.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('warehouse') }}</h3>
                  </v-card-title>
                </v-card>
                <v-card
                  outlined
                  @click="
                    $router.push({
                      name: 'dashboard-management',
                    })
                  "
                  v-if="$service.hideFrom((v) => [v == 5 || v == 9])"
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/project-management.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('management') }}</h3>
                  </v-card-title>
                </v-card>
              </div>
              <div class="d-flex">
                <v-card
                  v-if="$service.hideFrom((v) => [v == 5 || v == 1 || v == 9])"
                  @click="$router.push({ name: 'dashboard-sales' })"
                  outlined
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/sales.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('sales') }}</h3>
                  </v-card-title>
                </v-card>
                <v-card
                  outlined
                  v-if="$service.hideFrom((v) => [v == 5 || v == 9])"
                  @click="
                    $router.push({
                      name: 'dashboard-archive',
                      params: {
                        tab: 'orders',
                      },
                    })
                  "
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/archive.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('reports') }}</h3>
                  </v-card-title>
                </v-card>

                <v-card
                  outlined
                  @click="
                    $router.push({
                      name: 'dashboard-selling',
                    })
                  "
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/selling.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('selling-points') }}</h3>
                  </v-card-title>
                </v-card>
                <v-card
                  outlined
                  v-if="$service.hideFrom((v) => [v == 5 || v == 9])"
                  @click="
                    $router.push({
                      name: 'dashboard-follow-up',
                    })
                  "
                  class="item-card clickable"
                >
                  <v-card-title class="d-flex flex-column mt-5 py-5">
                    <v-img
                      src="../../../assets/svg/stats.png"
                      height="100"
                      width="100"
                    />
                    <h3 class="mt-3">{{ $t('follow-up') }}</h3>
                  </v-card-title>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
export default {
  computed: {
    company() {
      return this.$store.state.company;
    },
  },
  created() {},
};
</script>
