var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-card',{staticClass:"transparent",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"d-flex"},[_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard-accounts' })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/budget.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('accounts')))])],1)],1),(_vm.$service.hideFrom(function (v) { return [v == 5 || v == 9]; }))?_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'hr-users' })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/hr.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('hr')))])],1)],1):_vm._e(),_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard-warehouses' })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/warehouse.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('warehouse')))])],1)],1),(_vm.$service.hideFrom(function (v) { return [v == 5 || v == 9]; }))?_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'dashboard-management',
                  })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/project-management.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('management')))])],1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex"},[(_vm.$service.hideFrom(function (v) { return [v == 5 || v == 1 || v == 9]; }))?_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard-sales' })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/sales.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('sales')))])],1)],1):_vm._e(),(_vm.$service.hideFrom(function (v) { return [v == 5 || v == 9]; }))?_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'dashboard-archive',
                    params: {
                      tab: 'orders',
                    },
                  })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/archive.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('reports')))])],1)],1):_vm._e(),_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'dashboard-selling',
                  })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/selling.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('selling-points')))])],1)],1),(_vm.$service.hideFrom(function (v) { return [v == 5 || v == 9]; }))?_c('v-card',{staticClass:"item-card clickable",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'dashboard-follow-up',
                  })}}},[_c('v-card-title',{staticClass:"d-flex flex-column mt-5 py-5"},[_c('v-img',{attrs:{"src":require("../../../assets/svg/stats.png"),"height":"100","width":"100"}}),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t('follow-up')))])],1)],1):_vm._e()],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }